body{
  line-height: 2;
  font-weight: 400;
}
h1, h2, h3, h4, h5{
  font-family: "Roboto", Sans-serif;
  font-weight: 700;
}
li, a, p{
  font-family: "Roboto", Sans-serif;
  font-weight: 300;
}
hr{
  margin: 5rem auto;
  max-width: 70%;
}
.img-nav{
  max-width: 100px;
}
.div-landing{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.img-landing{
  width: 100%;
  max-width: 300px;
  margin-left: auto;
}

.div-about{
  min-height: 70vh;
}
.div-option{
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.p-description{
  line-height: 1.2;
}
.text-muted{ color: #404040 !important; }
footer{
  background-color: #face00;
}
footer a{
  color: #1f1f1f;
  text-decoration: none;
}
footer a:hover{
  color: #1f1f1f;
  text-decoration: underline;
}
footer ul {
  list-style-type: none;
}


@media screen and (max-width: 420px) {
  .img-footer{
    width: 100%;
  }
  ul { padding-left: 0;}
}

